import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Dev/Personal/kathryn-jones-nutrition/src/components/default-page-layout.js";
import plate from '../images/plate.avif';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Kathryn Jones Nutrition`}</h1>
    <p>
    <img src={plate} alt="A salad with greens, chick peas, cherry tomatoes, pumpkin and avocado" />
    </p>
    <h2>{`About The Practice`}</h2>
    <p>{`Welcome to Kathryn Jones Nutrition Pty Ltd.
Located in Cardiff, NSW.`}</p>
    <p>{`Dietetics practice, specialising in renal nutrition, malnutrition, oncology and enteral nutrition.`}</p>
    <p>{`My goal is to work with you, your lifestyle and health needs to provide individualised nutrition advice.`}</p>
    <p>{`I am dedicated to making you feel well supported in achieving your health goals.`}</p>
    <h2>{`About Kathryn, Director`}</h2>
    <p>{`I am an Accredited Practising Dietitian (APD) who works between a local hospital, and seeing clients through my private practice. `}</p>
    <p>{`When I'm not working, you’ll most likely find me baking up a storm, playing in the veggie garden, or enjoying a coffee with friends.`}</p>
    <p>{`You may notice my hobbies all involve food or drink of somesort - I am passionate about enjoying and celebrating good food, with good company. `}</p>
    <p>{`My goal is to help you understand how that can fit in with a healthy, balanced diet - yes, you can have your cake and eat it too!`}</p>
    <h2>{`Dietitian vs Nutritionist?`}</h2>
    <p>{`A nutritionist may be a tertiary qualified nutrition professional that has the expertise to provide a range of evidence based nutrition services related to nutrition, public health nutrition, policy and research, and community health. It’s important to note that the term ‘nutritionist’ is not regulated. Therefore, this title may be used by dietitians, nutrition scientists and nutrition graduates – and also those with very limited qualifications in nutrition. `}</p>
    <p>{`Dietitians are also qualified to provide this range of evidence based nutrition services, but in addition, dietitians have the expertise to provide individual dietary counselling, medical nutrition therapy, group dietary therapy and food service management. A dietitian has undertaken a course of study that included substantial theory and supervised and assessed professional practice in clinical nutrition, medical nutrition therapy and food service management `}</p>
    <p>{`In Australia all dietitians are nutritionists however nutritionists without a dietetics qualification cannot take on the expert role of a dietitian. `}</p>
    <p>{`Dietitians in Australia who meet strict criteria are eligible to join the Accredited Practising Dietitian (APD) program. APD is the only credential for dietitians recognised by Medicare and the Department of Veterans’ Affairs (DVA) and many private health insurers
(Ref: Dietitians Australia, 2020) `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      